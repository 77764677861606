<template>
<div class="s-frame">
    <a-layout>
        <a-layout-content>
            <a-row class="s-layout" style="padding-left:0;padding-right:0">
                <a-col :span="24" style="display:flex">
                    <div class="s-sidebar-div">
                        <div class="s-f-font0" style="margin-bottom:30px">키워드 찾기</div>
                        <div class="s-f-font4" v-bind:class="[ selectedSideBarMenu==SIDEMENU_TYPE.KEYWORD  ? 'active' : '' ]" @click="onSelectedSideBarMenu(SIDEMENU_TYPE.KEYWORD)">키워드로 찾기
                            <a-tooltip>
                                <template slot="title">
                                    이미 원하는 키워드가 있다면, 해당 키워드와 관련된 키워들을 한눈에 모아볼 수 있습니다
                                </template>
                                <img style="margin-left:6px" src="@/assets/images/v4-icon/question.svg" />
                            </a-tooltip>
                        </div>
                        <div class="s-f-font4" v-bind:class="[ selectedSideBarMenu==SIDEMENU_TYPE.CATEGORY  ? 'active' : '' ]" @click="onSelectedSideBarMenu(SIDEMENU_TYPE.CATEGORY)">카테고리로 찾기

                            <a-tooltip>
                                <template slot="title">
                                    네이버 쇼핑 카테고리별로 인기 키워드를 실시간 조회할 수 있습니다
                                </template>
                                <img style="margin-left:6px" src="@/assets/images/v4-icon/question.svg" />
                            </a-tooltip>

                        </div>
                        <div class="s-f-font4" v-bind:class="[ selectedSideBarMenu==SIDEMENU_TYPE.COMPETITIVE   ? 'active' : '' ]" @click="onSelectedSideBarMenu(SIDEMENU_TYPE.COMPETITIVE)">경쟁제품 키워드로 찾기
                            <a-tooltip>
                                <template slot="title">
                                    키워드별로 스마트스토어 40위제품의 제목, 태그 키워드를 한눈에 모아서 볼 수 있습니다
                                </template>
                                <img style="margin-left:6px" src="@/assets/images/v4-icon/question.svg" />
                            </a-tooltip>

                        </div>
                        <div style="margin-top:30px" v-if="selectedSideBarMenu==SIDEMENU_TYPE.KEYWORD">
                            <img style="width:100%" src="@/assets/images/v4-icon/s-f-img1.png" />
                        </div>
                        <div style="margin-top:30px" v-if="selectedSideBarMenu==SIDEMENU_TYPE.COMPETITIVE">
                            <img style="width:100%;cursor:pointer" src="@/assets/images/v4-icon/s-f-img3.png"  @click="$router.push('/pages/store/analysis').catch(() => {})"/>
                        </div>
                        <!-- <div style="margin-top:30px" v-show="selectedSideBarMenu==SIDEMENU_TYPE.CATEGORY">
                            <a-row>
                                <a-col :span="24" style="  margin-bottom: 10px;">
                                    <span class="s-f-font5">1차 카테고리</span>
                                </a-col>
                                <a-col :span="24">
                                    <div @click="onSelectCategory1(c)" v-for="c in categoryListDepth1" :key="c.cid" class="s-f-ctag" v-bind:class="[ selecteCategory1.cid==c.cid  ? 'active' : '' ]">{{c.name}}</div>
                                </a-col>
                                <a-col :span="24" style="margin-top:40px;margin-bottom: 10px;">
                                    <span class="s-f-font5">2차 카테고리</span>
                                </a-col>
                                <a-col :span="24">
                                    <div @click="onSelectCategory2(c)" v-for="c in categoryListDepth2" :key="c.cid" class="s-f-ctag" v-bind:class="[ selecteCategory2.cid==c.cid  ? 'active' : '' ]">{{c.name}}</div>
                                </a-col>
                                <a-col :span="24" style="margin-top:40px;margin-bottom: 10px;">
                                    <span class="s-f-font5">3차 카테고리</span>
                                </a-col>
                                <a-col :span="24">
                                    <div @click="onSelectCategory3(c)" v-for="c in categoryListDepth3" :key="c.cid" class="s-f-ctag" v-bind:class="[ selecteCategory3.cid==c.cid  ? 'active' : '' ]">{{c.name}}</div>
                                </a-col>
                            </a-row>
                        </div> -->
                    </div>
                    <div class="s-contents-div">
                        <div v-if="selectedSideBarMenu==SIDEMENU_TYPE.KEYWORD">
                            <div style="margin-top:0px">
                                <div style="background-color:#F8F8F8;width:100%;height:227px;margin-bottom:0px;padding:40px">
                                    <div class="s-f-font6">키워드로 찾기</div>
                                    <div class="s-f-font7">키워드와 관련된 다양한 네이버탭의 연관키워드를 한눈에 조회 가능하며, 핵심 키워드를 발굴 할 수 있습니다.</div>
                                    <div style="display:flex">
                                        <div>
                                            <a-input class="s-input1" style="width:360px" v-model="keyword" @pressEnter="onSearch('')" placeholder="키워드입력">
                                                <div slot="suffix">
                                                    <img src="@/assets/images/v4-icon/search.png" />
                                                </div>
                                            </a-input>
                                        </div>
                                        <div class="s-f-font8">
                                            해당 키워드에 유사어가 있다면, 유사어를 별도로<br />
                                            키워드를 검색 하는 것을 추천 드립니다. (예 : 프로바이오틱스-유산균)
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <find-table :code="code" :sidebar="selectedSideBarMenu" :keyword="skeyword" :viemode="SEARCH_TYPE.COMPLEX"></find-table>

                        </div>
                        <div v-if="selectedSideBarMenu==SIDEMENU_TYPE.CATEGORY">
                            <div style="margin-top:0px">
                                <div style="background-color:#F8F8F8;width:100%;height:227px;margin-bottom:0px;padding:40px">
                                    <div class="s-f-font6">카데고리로 찾기</div>
                                    <div class="s-f-font7">쇼핑카테고리와 관련된 카테고리별 실시간 인기 키워드를 통해 핵심키워드를 발굴할 수 있습니다.</div>
                                    <div style="display:flex">
                                        <div>
                                            <a-select class="s-f-s-option" style="width:300px" :v-model="selectedCategoryCode1" :getPopupContainer="triggerNode => triggerNode.parentNode" optionFilterProp="children" size="large" @change="onSelectCategory1">
                                                <a-select-option class="" :value="c.cid" v-for="(c,index) in categoryListDepth1" :key="index">
                                                    {{c.name}}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                        <div>
                                            <a-select class="s-f-s-option" style="width:300px" :v-model="selectedCategoryCode2" :getPopupContainer="triggerNode => triggerNode.parentNode" optionFilterProp="children" size="large" @change="onSelectCategory2">
                                                <a-select-option class="" :value="c.cid" v-for="(c,index) in categoryListDepth2" :key="index">
                                                    {{c.name}}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                        <div>
                                            <a-select class="s-f-s-option" style="width:300px" :v-model="selectedCategoryCode3" :getPopupContainer="triggerNode => triggerNode.parentNode" optionFilterProp="children" size="large" @change="onSelectCategory3">
                                                <a-select-option class="" :value="c.cid" v-for="(c,index) in categoryListDepth3" :key="index">
                                                    {{c.name}}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="s-f-font2" style="padding-bottom:21px"><span>{{sCategory}}</span> 카테고리 검색결과</div>
                            <div class="s-f-tab" style="display:flex;">
                                <div @click="selectedTab=SEARCH_TYPE.TOP10TAB" v-bind:class="['', selectedTab==SEARCH_TYPE.TOP10TAB ? activeClass : '']"><img class="s-f-filter-default" src="@/assets/images/v4-icon/s-f-svg-1.svg">&nbsp;카테고리TOP10</div>
                                <div @click="onSelectTab(SEARCH_TYPE.TODAYFAVTAB)" v-bind:class="['', selectedTab==SEARCH_TYPE.TODAYFAVTAB ? activeClass : '']"><img class="s-f-filter-default" src="@/assets/images/v4-icon/s-f-svg-2.svg">&nbsp;오늘의인기검색어</div>
                                <div @click="onSelectTab(SEARCH_TYPE.WEEKFAVTAB)" v-bind:class="['', selectedTab==SEARCH_TYPE.WEEKFAVTAB ? activeClass : '']"><img class="s-f-filter-default" src="@/assets/images/v4-icon/s-f-svg-3.svg">&nbsp;주간인기검색어</div>
                                <div @click="onSelectTab(SEARCH_TYPE.MONTHFAVTAB)" v-bind:class="['', selectedTab==SEARCH_TYPE.MONTHFAVTAB ? activeClass : '']"><img class="s-f-filter-default" src="@/assets/images/v4-icon/s-f-svg-4.svg">&nbsp;월간인기검색어</div>
                              
                            </div> -->

                            <find-table :code="code" :category="sCategory" :sidebar="selectedSideBarMenu" :keyword="skeyword" :viemode="SEARCH_TYPE.SIMPLE"></find-table>
                        </div>
                        <div v-if="selectedSideBarMenu==SIDEMENU_TYPE.COMPETITIVE">
                              <div style="margin-top:0px">
                                <div style="background-color:#F8F8F8;width:100%;height:227px;margin-bottom:0px;padding:40px">
                                    <div class="s-f-font6">경쟁제품 키워드로 찾기</div>
                                    <div class="s-f-font7">키워드찾기에서 저장한 키워드를 관리하며, 기간별 키워드 검색동향을 한눈에 비교할 수 있습니다. (키워드 10개까지 비교가능)</div>
                                    <div style="display:flex">
                                        <div>
                                            <a-input class="s-input1" style="width:360px" v-model="keyword" @pressEnter="notSupported()" placeholder="키워드입력">
                                                <div slot="suffix">
                                                    <img src="@/assets/images/v4-icon/search.png" />
                                                </div>
                                            </a-input>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <find-table :code="code" :sidebar="selectedSideBarMenu" :keyword="skeyword" :viemode="SEARCH_TYPE.EMPTY"></find-table>

                        </div>
                    </div>

                </a-col>
            </a-row>
        </a-layout-content>
    </a-layout>
</div>
</template>

<script>
import {
  getCurrentTimeNew,
  getHex,
  replaceAll,
  chkLicense,
  showError,
  showSuccess,
  isMobile,
  SEARCH_TYPE,
  SIDEMENU_TYPE
} from "../../components/fnc.js";
import firebase from "firebase";
import FindTable from "../../components/FindTable/index.vue";
export default {
  components: {
    FindTable
  },
  data() {
    return {
      SEARCH_TYPE: SEARCH_TYPE,
      SIDEMENU_TYPE: SIDEMENU_TYPE,
      activeClass: "active",
      selectedTab: SEARCH_TYPE.RELATETAB,
      selectedSideBarMenu: SIDEMENU_TYPE.KEYWORD,
      keyword: "",
      skeyword: "",
      sCategory: "",

      code: "",
      categoryListDepth1: [],
      categoryListDepth2: [],
      categoryListDepth3: [],
      selectedCategoryCode1: "",
      selectedCategoryCode2: "",
      selectedCategoryCode3: "",
      selectedCategoryName1: "",
      selectedCategoryName2: "",
      selectedCategoryName3: "",

      loading: false
    };
  },
  methods: {
    notSupported() {
      var self = this;
      showSuccess({
        notify: self.$vs.notify,
        msg: "준비중인 메뉴입니다."
      });
    },
    onSelectedSideBarMenu(idx) {
      this.selectedSideBarMenu = idx;
      this.categoryListDepth2 = [];
      this.categoryListDepth3 = [];

      this.code = "";

      if (idx == SIDEMENU_TYPE.CATEGORY) {
        this.keyword = "";
        this.skeyword = "";
        this.selectedTab = SEARCH_TYPE.TOP10TAB;
      }
      if (idx == SIDEMENU_TYPE.KEYWORD) {
        this.keyword = "";
        this.skeyword = "";
        this.selectedTab = SEARCH_TYPE.RELATETAB;
      }

      if (idx == SIDEMENU_TYPE.COMPETITIVE) {
        this.keyword = "";
        this.skeyword = "";
        this.selectedTab = SEARCH_TYPE.COMPETITIVETAB;
      }
    },
    onSelectCategory1(code) {
      this.selectedCategoryCode1 = code;

      this.selectedTab = SEARCH_TYPE.TOP10TAB;

      // this.sCategory = e;
      this.selectedCategoryName1 = this.categoryListDepth1.find(
        item => item.cid == code
      )["name"];

      this.sCategory = this.selectedCategoryName1;
      this.categoryListDepth2 = [];
      this.categoryListDepth3 = [];
      this.selectedCategoryCode2 = "";
      this.selectedCategoryCode3 = "";
      this.selectedCategoryName2 = "";
      this.selectedCategoryName3 = "";

      this.onGetCategoryList(2, code);
      this.onSearch(code);
    },
    onSelectCategory2(code) {
      this.categoryListDepth3 = [];
      this.selectedCategoryCode2 = code;
      this.selectedCategoryCode3 = "";

      this.selectedCategoryName2 = this.categoryListDepth2.find(
        item => item.cid == code
      )["name"];

      this.sCategory =
        this.selectedCategoryName1 + " > " + this.selectedCategoryName2;
      this.onGetCategoryList(3, code);
      this.onSearch(code);
    },
    onSelectCategory3(code) {
      // console.log("c", code);
      this.selectedCategoryCode3 = code;
      this.selectedCategoryName3 = this.categoryListDepth3.find(
        item => item.cid == code
      )["name"];

      // console.log(this.categoryListDepth3.find(item => item.cid == code));
      this.sCategory =
        this.selectedCategoryName1 +
        " > " +
        this.selectedCategoryName2 +
        " > " +
        this.selectedCategoryName3;
      this.onSearch(code);
    },
    async onSelectTab(_tab) {
      if (
        this.selectedSideBarMenu == SIDEMENU_TYPE.CATEGORY &&
        (this.selecteCategory2.cid != "" || this.selecteCategory3.cid != "")
      ) {
        showError({
          notify: this.$vs.notify,
          msg: "1차 카데고리만 가능합니다."
        });
      } else {
        this.selectedTab = _tab;
      }
    },
    async onSearch(code) {
      var keyword = this.keyword.toUpperCase();
      keyword = replaceAll(keyword, " ", "");
      this.skeyword = keyword;

      if (this.selectedSideBarMenu == SIDEMENU_TYPE.KEYWORD) {
        this.selectedTab = SEARCH_TYPE.RELATETAB;
      }
      if (this.selectedSideBarMenu == SIDEMENU_TYPE.CATEGORY) {
        this.selectedTab = SEARCH_TYPE.TOP10TAB;
        this.code = code;
      }
      this.keyword = "";
      this.$vs.loading.close();
    },
    onGetCategoryList(depth, code) {
      var self = this;

      this.$http
        .post(
          "https://asia-east2-storelink-fnc4-41621.cloudfunctions.net/onGetNaverCategory",
          {
            cid: code
          }
        )
        .then(function(r) {
          if (depth == 1) {
            self.categoryListDepth1 = r.data.childList;
          }
          if (depth == 2) {
            self.categoryListDepth2 = r.data.childList;
          }
          if (depth == 3) {
            self.categoryListDepth3 = r.data.childList;
            console.log(self.categoryListDepth3);
          }
        });
    }
  },
  mounted() {
    this.onGetCategoryList(1, "");
  }
};
</script>

<style>
.s-f-tab > div {
  margin-right: 40px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.03em;
  cursor: pointer;
  color: #828282;
}

.s-f-tab > div:hover,
.s-f-tab > div.active {
  font-weight: 500;
  color: #000000;
}

.s-f-font0 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.03em;

  color: #000000;
}

.s-f-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.03em;
  cursor: pointer;
  color: #000000;
}

.s-f-font4 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.03em;
  cursor: pointer;
  color: #000000;
  padding: 14px;
  border-bottom: 1px solid #e0e0e0;
}

.s-f-font4.active {
  font-weight: bold;
  background: rgba(2, 100, 251, 0.1);
  border-left: 6px solid #0264fb;
}

.s-f-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 35px;
  /* identical to box height */
  letter-spacing: -0.03em;

  color: #000000;
}

.s-f-font2 > span {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  /* identical to box height */

  letter-spacing: -0.03em;
}

.s-f-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.03em;
}

.s-f-font3.active {
  color: #000000;
}

.s-f-font5 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: #000000;
}

.s-f-font6 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: -0.03em;
  padding-bottom: 10px;
  color: #000000;
}

.s-f-font7 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;
  padding-bottom: 20px;

  color: #000000;
}

.s-f-font8 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.03em;

  color: #828282;
  padding-left: 20px;
  padding-top: 10px;
}

.s-f-ctag {
  height: 32px;
  display: inline-block;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.03em;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  cursor: pointer;
}

.s-f-ctag.active {
  background-color: #0264fb;
  color: white;
}

.s-f-s-option {
  width: 240px;
  margin-right: 10px;
}

.s-f-s-option .ant-select-selection {
  width: 100%;
  height: 60px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.s-f-s-option .ant-select-selection-selected-value {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;
  padding-left: 0px;
  padding-top: 18px;
  color: #000000;
}
</style>
